const needs = {
  title: 'צרכים',
  items: [
    {
      title: 'צרכים פנימיים',
      items: [
        {
          title: 'תחושת עצמי',
          items: [
            'מסוגלות',
            'עוצמה',
            'יושרה',
            'ביטוי עצמי',
            'חיבור עצמי',
            'ערך עצמי',
            'כנות',
            'קבלה עצמית',
            'ריפוי',
            'כוח פנימי',
          ],
        },
        {
          title: 'משמעות',
          items: [
            'מימוש',
            'מיצוי החיים',
            'תרומה',
            'נתינה',
            'צמיחה',
            'הגשמה',
            'יעילות',
            'בהירות',
            'גילוי',
            'למידה',
            'התפתחות',
          ],
        },
        {
          title: 'בטחון רגשי',
          items: [
            'ודאות',
            'יציבות',
            'סדר',
            'מבנה',
            'עקביות',
            'הגנה',
            'אמון',
            'לסמוך על',
          ],
        },
        {
          title: 'חגיגת החיים',
          items: [
            'צחוק',
            'הנאה',
            'משחקיות',
            'התחדשות',
            'השראה',
            'זרימה',
            'יצירתיות',
            'קלילות',
            'הומור',
            'שעשוע',
            'יופי',
            'תמימות',
          ],
        },
        {
          title: 'נפשי רוחני',
          items: [
            'שלמות פנימית',
            'הרמוניה',
            'אחדות',
            'חיבור לרוח',
            'שקט',
            'שלווה',
            'תקווה',
            'אמונה',
            'אבל',
          ],
        },
        {
          title: 'הישרדות',
          items: [
            'ביטחון פיזי',
            'אויר',
            'מים',
            'מזון',
            'מחסה',
            'בריאות',
            'מנוחה',
            'שינה',
            'המשכיות',
            'תנועה',
          ],
        },
      ],
    },
    {
      title: 'צרכים חיצוניים',
      items: [
        {
          title: 'חיבור',
          items: [
            'הקשבה',
            'הכרה',
            'הערכה',
            'נראות',
            'כבוד',
            'התייחסות',
            'קבלה',
            'חמלה',
            'אמפתיה',
            'אכפתיות',
            'תשומת לב',
            'התחשבות',
            'הבנה',
          ],
        },
        {
          title: 'אינטימיות',
          items: [
            'קרבה',
            'חברות',
            'אהבה',
            'עדינות',
            'רכות',
            'חיבור',
            'אותנטיות',
            'מגע',
          ],
        },
        {
          title: 'קהילה',
          items: [
            'שייכות',
            'שותפות',
            'תמיכה',
            'תקשורת',
            'שיתוף פעולה',
            'החזקה משותפת',
            'ערבות הדדית',
            'הדדיות',
            'שיוויוניות',
          ],
        },
        {
          title: 'חופש',
          items: ['נפרדות', 'עצמאות', 'בחירה', 'אוטונומיה', 'אחריות', 'מרחב'],
        },
      ],
    },
  ],
};

export default needs;
