const feelings = {
  title: 'רגשות',
  items: [
    {
      title: 'רגשות כשצרכים לא נענים',
      items: [
        {
          title: 'פחד',
          items: [
            'חשדנות',
            'דאגה',
            'חשש',
            'רתיעה',
            'בהלה',
            'חרדה',
            'אימה',
            'קנאה',
          ],
        },
        {
          title: 'עצב',
          items: [
            'עגמומיות',
            'דכדוך',
            'געגוע',
            'בדידות',
            'צער',
            'יגון',
            'אבל',
            'יאוש',
          ],
        },
        {
          title: 'אשמה',
          items: ['אכזבה', 'החמצה', 'תסכול'],
        },
        {
          title: 'שיעמום',
          items: ['אדישות', 'חוסר סבלנות', 'ריחוק'],
        },
        {
          title: 'בלבול',
          items: [
            'מבוכה',
            'בושה',
            'היסוס',
            'מוטרדות',
            'תהייה',
            'תדהמה',
            'אי-נוחות',
          ],
        },
        {
          title: 'כעס',
          items: [
            'באסה',
            'עצבנות',
            'עלבון',
            'פגיעות',
            'רוגז',
            'מרמור',
            'הלם',
            'זעם',
            'טינה',
            'מרירות',
            'ניכור',
            'שנאה',
            'סלידה',
          ],
        },
        {
          title: 'תחושות בגוף',
          items: [
            'כאב',
            'כיווץ',
            'תשישות',
            'עייפות',
            'כבדות',
            'מתח',
            'לחץ',
            'מועקה',
            'שיתוק',
            'ניתוק',
            'רייקנות',
            'עומס',
            'אי-שקט',
            'צמרמורת',
            'בחילה',
          ],
        },
      ],
    },
    {
      title: 'רגשות כשצרכים נענים',
      items: [
        {
          title: 'שלווה',
          items: [
            'רגיעה',
            'שקט',
            'סיפוק',
            'שוויון נפש',
            'שלום פנימי',
            'נחת',
            'מפוייסות',
          ],
        },
        {
          title: 'לב פתוח',
          items: [
            'אהבה',
            'אכפתיות',
            'הכרת תודה',
            'חמלה',
            'רכות',
            'קרבה',
            'קלילות',
            'אהדה',
            'חיבה',
            'מסירות',
          ],
        },
        {
          title: 'שמחה',
          items: [
            'אושר',
            'התלהבות',
            'התרוממות רוח',
            'עליזות',
            'הפתעה',
            'גאווה',
            'עוצמה',
            'מלאות',
            'תקווה',
            'שעשוע',
            'שביעות רצון',
            'תקווה',
            'התרגשות',
            'ביטחון',
            'התפעלות',
          ],
        },
        {
          title: 'תחושות בגוף',
          items: [
            'התרחבות',
            'נעימות',
            'חיוניות',
            'רעננות',
            'נינוחות',
            'עירנות',
            'הנאה',
            'דשנות',
          ],
        },
      ],
    },
  ],
};

export default feelings;
